/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup";
import { register } from "redux/actionCreators/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


const SignupPage: React.FunctionComponent<IPage> = props => {

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        username: Yup.string().required("Username is required"),
        fullname: Yup.string().required("Full name is required"),
        email: Yup.string().email("Email is invalid").required("Email is required"),
        password: Yup.string().required("Password is required").matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
            .min(8, ({ min }) => `Password must be at least ${min} characters`),
        month: Yup.number().positive('Month is required').required("Month is required"),
        year: Yup.number().positive("Year is required").required("Year is required"),
        day: Yup.number().positive("Day is required").required("Day is required"),
    });

    const styles = {
        label: 'block text-gray-700 text-sm font-bold pt-2 pb-1',
        field:
            'bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none',
        button:
            'w-full disabled:cursor-not-allowed disabled:shadow-none disabled:text-white disabled:transform-none disabled:transition-none  cursor-pointer flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        errorMsg: 'text-[#F42852] text-sm pb-2 font-bold mt-2 pt-2',
    }

    const initialValues = { email: "", password: "", username: "", fullname: "", gender: "", month: "", day: "", year: "" };
    const [isSubmitting, setIsSubmitting] = useState(false);

    let minOffset = 0, maxOffset = 60;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }

    const onSubmit = async (values: any) => {
        setIsSubmitting(true);

        const payload = {

            username: values.username,
            fullname: values.fullname,
            email: values.email,
            password: values.password,
            gender: values.gender,
            month: values.month,
            year: values.year,
            day: values.day,
            dob: `${values.month}-${values.day}-${values.year}`
        }
        const userRegister: any = await register(payload);

        if (userRegister.data.error) {

            toast.error(userRegister.data.message);
        } else {

            localStorage.setItem("email", values.email);

            navigate('/verify-email');
        }

        setIsSubmitting(false);
    };

    return (
        <React.Fragment>

            <div className="bg-[#000] font overflow-x-hidden">
                <div>
                    <div className="flex justify-between max-[767px]:block">
                        <div className="col-1 bg-[#1a1a1a]  font-['Poppins-Med'] max-[767px]:w-[100%] justify-center items-center w-[50%] flex bg-[url('https://content.sweatsonic.com/right-side.png')]">
                            <div className="max-[767px]:py-[100px]">
                                <p className="text-[20px] text-[#E7EAEE] font-[500] text-center">
                                    Create New Account
                                </p>
                                <h1 className="text-[48px] font-[600] text-[#E7EAEE] text-center">
                                    Join <span className="text-[#05BEF8]">SweatSonic</span>
                                </h1>
                                <p className="text-[14px] font-['Pop-L'] text-[#E7EAEE] font-[500] text-center">
                                    Start with a 14-day free trail, cancel anytime!
                                </p>
                            </div>
                        </div>
                        <div className="w-[50%] font-['Pop-L'] my-[30px] px-[80px] flex justify-center flex-col max-[767px]:w-[100%] max-[767px]:px-[24px] max-[767px]:py-[100px]">
                            <div className="m-auto w-[645px] max-[1460px]:w-[100%] p-[40px] bg-[#151515] rounded-lg">
                                <h2 className="text-2xl font-['Poppins-Med'] text-white font-bold mb-2">
                                    Basic info
                                </h2>
                                <p className="text-[#D0D5DD] text-[16px] font-[400] mb-[8]">
                                    Enter your basic information to create your Sweat Sonic profile
                                </p>
                                <p className="text-[#F42852] text-[16px] mb-[24px]">*All fields required unless noted.</p>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched }) => (
                                        <Form autoComplete="off">
                                            <div className="mb-[24px]">
                                                <label htmlFor="username" className="block text-gray-400 mb-2">
                                                    Username
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="username"
                                                    placeholder="sarah"
                                                    autoComplete="off"
                                                    className={`${errors.username && touched.username ? 'bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0 text-[#D0D5DD] border border-red-500 rounded-[8px] w-full px-3 py-2 rounded ' : 'bg-[transparent] text-[#D0D5DD] border border-[#D0D5DD] rounded-[8px] w-full px-3 py-2 rounded '}`}
                                                />

                                            </div>
                                            <div className="mb-[24px]">
                                                <label htmlFor="email" className="block text-gray-400 mb-2">
                                                    Email
                                                </label>
                                                <Field
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="noiseland@yahoo.com"
                                                    className={`${errors.email && touched.email ? 'bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0 text-[#D0D5DD] border border-red-500 rounded-[8px] w-full px-3 py-2 rounded ' : 'bg-[transparent] text-[#D0D5DD] border border-[#D0D5DD] rounded-[8px] w-full px-3 py-2 rounded '}`}
                                                />
                                            </div>
                                            <div className="mb-[24px]">
                                                <label htmlFor="fullname" className="block text-gray-400 mb-2">
                                                    Full Name
                                                </label>
                                                <Field
                                                    type="text"
                                                    id="fullname"
                                                    name="fullname"
                                                    placeholder="Sarah A"
                                                    className={`${errors.fullname && touched.fullname ? 'bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0 text-[#D0D5DD] border border-red-500 rounded-[8px] w-full px-3 py-2 rounded ' : 'bg-[transparent] text-[#D0D5DD] border border-[#D0D5DD] rounded-[8px] w-full px-3 py-2 rounded '}`}
                                                />


                                            </div>
                                            <div className="mb-[24px]">
                                                <label htmlFor="password" className="block text-gray-400 mb-2">
                                                    Password
                                                </label>
                                                <Field
                                                    type="password"
                                                    id="password"
                                                    placeholder="********"
                                                    name="password"
                                                    className={`${errors.password && touched.password ? 'bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0 text-[#D0D5DD] border border-red-500 rounded-[8px] w-full px-3 py-2 rounded ' : 'bg-[transparent] text-[#D0D5DD] border border-[#D0D5DD] rounded-[8px] w-full px-3 py-2 rounded '}`}
                                                />
                                                {/* <p className="text-sm text-red-500 mt-1">
                                            Your password must contain at least one special character
                                            (!@#$%^&amp;*)
                                        </p> */}

                                                <ErrorMessage component='p' className={styles.errorMsg} name='password' />

                                            </div>
                                            <div className="mb-[24px]">
                                                <fieldset className="mb-[24] flex">
                                                    <legend className="text-gray-400 mb-1">
                                                        What's your gender? (optional)
                                                    </legend>
                                                    <div className="flex items-center mb-2">
                                                        <input
                                                            id="female"
                                                            type="radio"
                                                            name="gender"
                                                            className="text-blue-600 bg-gray-700 border-gray-600"
                                                        />
                                                        <label htmlFor="female" className="ml-2 text-gray-400">
                                                            Female
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center mb-2 mx-[48px]">
                                                        <input
                                                            id="male"
                                                            type="radio"
                                                            name="gender"
                                                            className="text-blue-600 bg-gray-700 border-gray-600"
                                                        />
                                                        <label htmlFor="male" className="ml-2 text-gray-400">
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center mb-2">
                                                        <input
                                                            id="non-binary"
                                                            type="radio"
                                                            name="gender"
                                                            className="text-blue-600 bg-gray-700 border-gray-600"
                                                        />
                                                        <label htmlFor="non-binary" className="ml-2 text-gray-400">
                                                            Non-binary
                                                        </label>
                                                    </div>


                                                </fieldset>

                                                <label htmlFor="dob" className="block text-gray-400 mb-2">
                                                    What's your date of birth?
                                                </label>
                                                <div className="flex gap-[20px] mb-[24px]">
                                                    <div className="flex flex-col">
                                                        <label className="text-[#B8C0CC]">Month</label>
                                                        <Field
                                                            as="select"
                                                            id="month"
                                                            name="month"
                                                            className="bg-[transparent] max-[1460px]:w-[100px] w-[175px] text-[#D0D5DD] border border-[#D0D5DD] px-3 py-2 rounded"
                                                        >
                                                            <option value={-1}>Month</option>
                                                            {Array(12).fill(0).map((_, i) => (
                                                                <option value={('0' + (i + 1)).slice(-2)}>{('0' + (i + 1)).slice(-2)}</option>
                                                            ))}
                                                        </Field>

                                                        <ErrorMessage component='p' className={styles.errorMsg} name='month' />

                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className="text-[#B8C0CC]">Day</label>
                                                        <Field
                                                            as="select"
                                                            id="day"
                                                            name="day"
                                                            className="bg-[transparent] max-[1460px]:w-[100px] w-[175px] text-[#D0D5DD] border border-[#D0D5DD] px-3 py-2 rounded"
                                                        >
                                                            <option value={-1}>Day</option>
                                                            {Array(12).fill(0).map((_, i) => (
                                                                <option value={('0' + (i + 1)).slice(-2)}>{('0' + (i + 1)).slice(-2)}</option>
                                                            ))}
                                                        </Field>

                                                        <ErrorMessage component='p' className={styles.errorMsg} name='day' />

                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className="text-[#B8C0CC]">Year</label>
                                                        <Field
                                                            as="select"
                                                            id="year"
                                                            name="year"
                                                            className="bg-[transparent] max-[1460px]:w-[100px] w-[175px] text-[#D0D5DD] border border-[#D0D5DD] px-3 py-2 rounded"
                                                        >
                                                            <option value={-1}>Year</option>
                                                            {allYears.map((x) => { return (<option key={x}>{x}</option>) })}
                                                        </Field>

                                                        <ErrorMessage component='p' className={styles.errorMsg} name='year' />

                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="bg-[#05BEF8] font-['Poppins-Med'] text-[#fff] h-[46px] w-full py-2 rounded-[8px]"
                                            >
                                                {isSubmitting ? (
                                                    <>

                                                        <div role="status">
                                                            <svg aria-hidden="true" className=" text-white inline w-6 h-6 text-white-200 animate-spin dark:text-white-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                            </svg>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>

                                                    </>
                                                ) : (

                                                    <>

                                                        Next

                                                    </>
                                                )}
                                            </button>
                                            <div className="text-center text-[14px] text-[#D0D5DD] mt-[24px]">
                                                By creating an account, you agree to the{" "}
                                                <a href="#" className="text-blue-600 hover:underline">
                                                    Terms of use
                                                </a>{" "}
                                                and{" "}
                                                <a href="#" className="text-blue-600 hover:underline">
                                                    Privacy Policy
                                                </a>
                                                .
                                            </div>
                                            <div className="text-center text-[14px] text-[#D0D5DD] mt-[24px]">
                                                Already a member?{" "}
                                                <a href="#" className="text-blue-600 hover:underline">
                                                    Log in
                                                </a>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme='dark'
            />


        </React.Fragment>
    );
};

export default SignupPage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "assets/css/global.scss";
import { BannerLogo, Logo, Profile } from "icons";
import { FileUploader } from 'react-drag-drop-files';
import { ToastContainer, toast } from "react-toastify";


interface RootState {
    auth: any;
}

const SetProfilePage: React.FunctionComponent<IPage> = (props) => {
    const navigate = useNavigate();


    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [imageType, setImageType] = useState(null);
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);


    const fileTypes = ['JPG', 'PNG', 'JPEG'];

    const handleChange = (file) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            console.log(img.width, img.height);
            if (img.width > 1200 && img.height > 1200) {
                toast.error(`Image dimentions must be smaller than 1200x1200px`);
            } else {
                setPreviewUrl(URL.createObjectURL(file));
                setFile(file);

                setImageName(file.name);
                setImageType(file.type);

                const size = file.size / 1024 / 1024;

                if (size > 2) {

                    toast.error(`Image should be no more than 2mb`);
                }

                const reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onload = () => {
                    setImage(reader.result);
                    localStorage.setItem("user_image",reader.result as string)
                };
            }
        };
    };

    return (
        <React.Fragment>

            <div className="text-center h-[68px] bg-[#232323] pt-[10px] pr[0px] pb-[10px] pl-[0px]">
                <BannerLogo className="mx-auto mt-[10px]" />
            </div>

            <div className="bg-[#000] font-['SF-Pro'] text-white flex ite justify-center py-[50px]">
                <div className="m-auto rounded-[8px] w-[500px] bg-[#1E1E1E] p-[32px]">
                    <div className="text-center">
                        {previewUrl ? (
                            <>
                                <img src={previewUrl} alt="profile" className="w-[65px] mx-auto rounded-full w-[65px] h-[65px]" />
                            </>
                        ) : (
                            <Profile className="w-[65px] mx-auto" />
                        )}
                        <p className="text-[16px] text-[#E7EAEE] mt-[12px] mb-[8px] leading-[27px]">
                            Let’s get you setup!
                        </p>
                        <h2 className="text-4xl text-white font-bold mb-[12px]">
                            Set Your Profile Pic
                        </h2>
                        <FileUploader
                            name='file'
                            handleChange={handleChange}
                            types={fileTypes}
                            className='practive-box-holder practive-box-holder-size'
                            maxSize={25}
                        >
                            <button className="border border-[#E7EAEE] underline text-[14px] w-[115px] text-[#E7EAEE] px-[16px] py-2 rounded-[4px] mb-8">
                                Select Image
                            </button>
                        </FileUploader>
                    </div>
                    <div className="flex justify-between items-center mb-6">
                        <button className="text-[#828282] underline text-[16px]" onClick={() => navigate("/set-goals")}>
                            Skip
                        </button>
                        <button className="bg-[#00A3FF] hover:bg-[#003D51] w-[94px] text-[#fff] h-[46px] py-2 rounded-[8px]" onClick={() => navigate("/set-goals")}>
                            Next
                        </button>
                    </div>
                    <div className="text-left border-t border-gray-600 pt-6 text-[14px]">
                        <p className="text-[#A0ABBB] text-sm">Make sure your picture is</p>
                        <p className="text-[#A0ABBB] text-sm">I. less than 2MB</p>
                        <p className="text-[#A0ABBB] text-sm">
                            II. has dimensions smaller than 1200x1200px
                        </p>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="dark"
            />


        </React.Fragment>
    );
};

export default SetProfilePage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/logo.svg";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "assets/css/global.scss";
import { BannerLogo, HumanBody, Logo, Subscribed } from "icons";
import Fire from "icons/fire";
import Clock from "icons/clock";

interface RootState {
  auth: any;
}

const SubscribeProgramPage: React.FunctionComponent<IPage> = (props) => {
  const navigate = useNavigate();

  const [subscribe_1,setSubscribe1] = useState(0);
  const [subscribe_2,setSubscribe2] = useState(0);

  return (
    <React.Fragment>
      <div className=" text-center h-[68px] bg-[#232323] pt-[10px] pr[0px] pb-[10px] pl-[0px]">
                <BannerLogo className="mx-auto mt-[10px]" />
      </div>
      <div className="bg-black font-['SF-Pro'] text-white py-[50px]">
        <div className="flex rounded-[8px] p-[32px] mx-auto flex-col w-[500px] bg-[#303030] p-4">
          <div className="mb-[30px]">
            <HumanBody className="w-[65px] mx-auto" />
          </div>
          <h1 className="text-[16px] font-[600] text-center text-[#D0D5DD]">
            Let's get you setup!
          </h1>
          <h2 className="text-[40px] font-[500] text-[#E7EAEE] text-center">
            Pick A Program
          </h2>
          <div className="border border-x-0 border-b-0 border-t border-[#A0ABBB] mt-[32px] pb-[32px]"></div>
          <div className="w-full max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div className="bg-[url('https://content.sweatsonic.com/workout-card.png')] px-[24px] bg-cover">
              <h3 className="transform skew-x-[106px] skew-[10px] text-center pt-[180px] text-[18px] font-[1000] font-['SF Pro'] leading-[34px] tracking-[2px]">
                LEG RAISES
              </h3>
              <div className="flex pt-[10px] justify-center">
                <p className="flex text-[15px] items-center w-[78px] h-[24px] bg-[#303030CC] justify-center rounded-[100px]">
                  <Clock className="h-[16px]" />
                  10:37
                </p>
                <p className="flex text-[15px] items-center w-[62px] ml-[8px] h-[24px] bg-[#303030CC] justify-center rounded-[100px]">
                  <Fire className="h-[16px]" />
                  X6
                </p>
              </div>
              <div className="flex pt-[15px] pb-[30px] justify-center">
                <button onClick={() => setSubscribe1(1)} className={subscribe_1 ? 'w-[127px] text-[14px] font-[600] flex items-center h-[33px] bg-[#00A3FF] rounded-[8px] text-white px-6' : 'w-[127px] text-[14px] font-[600] flex items-center h-[33px] bg-[#00A3FF] rounded-[8px] text-white px-8'}>
                  {subscribe_1 ? (
                     <>
                        Subscribed
                        <Subscribed className="ml-[8px]" />
                     </>
                  ): (
                     <>
                        Subscribe
                     </>
                  )}
                </button>
              </div>
            </div>

            <div className="bg-[url('https://content.sweatsonic.com/workout-card.png')] px-[24px] bg-cover">
              <h3 className="transform skew-x-[106px] skew-[10px] text-center pt-[180px] text-[18px] font-[1000] font-['SF Pro'] leading-[34px] tracking-[2px]">
                STEP UPS
              </h3>
              <div className="flex pt-[10px] justify-center">
                <p className="flex text-[15px] items-center w-[78px] h-[24px] bg-[#303030CC] justify-center rounded-[100px]">
                  <Clock className="h-[16px]" />
                  10:37
                </p>
                <p className="flex text-[15px] items-center w-[62px] ml-[8px] h-[24px] bg-[#303030CC] justify-center rounded-[100px]">
                  <Fire className="h-[16px]" />
                  X6
                </p>
              </div>
              <div className="flex pt-[15px] pb-[30px] justify-center">
                <button onClick={() => setSubscribe2(1)} className={subscribe_2 ? 'w-[127px] text-[14px] font-[600] flex items-center h-[33px] bg-[#00A3FF] rounded-[8px] text-white px-6' : 'w-[127px] text-[14px] font-[600] flex items-center h-[33px] bg-[#00A3FF] rounded-[8px] text-white px-8'}>
                  {subscribe_2 ? (
                     <>
                        Subscribed
                        <Subscribed className="ml-[8px]" />
                     </>
                  ): (
                     <>
                        Subscribe
                     </>
                  )}
                </button>
              </div>
            </div>
            
          </div>
          <div className="text-left mb-[24px]">
            <span className="cursor-pointer text-[#757575] hover:underline" onClick={() => navigate("/choose-plan")} >
              Skip
            </span>
            <p className="font-['Pop-L'] text-[#B2B2B2] text-[14px]">
              We use this information to build better routines for you.
            </p>
          </div>
          <div className="flex justify-between space-x-4" >
            <button onClick={() => navigate("/set-goals")}  className="bg-[transparent] font-[600] border border-[#757575] rounded-[6px] w-[205px] h-[46px] text-[#757575] py-2 px-6 rounded-md transition duration-300">
              Back
            </button>
            <button onClick={() => navigate("/choose-plan")}  className="w-[205px] h-[46px] font-[600] bg-[#00A3FF]  rounded-[6px] hover:bg-[#003D51] text-white py-2 px-6 rounded-md transition duration-300">
              Next
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeProgramPage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import IPage from "interfaces/page";
import { useNavigate } from "react-router-dom";
import "assets/css/global.scss";
import cookie from "js-cookie";
import { Logo } from "icons";

const HomePage: React.FunctionComponent<IPage> = (props) => {
  const navigate = useNavigate();

  const userToken = cookie.get("access_token");

  return (
    <React.Fragment>
        <div className="text-white">
            {`Home, user token is ${userToken.substring(0, 10)}...`}
        </div>
    </React.Fragment>
  );
};

export default HomePage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/logo.svg";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "assets/css/global.scss";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

interface RootState {
    auth: any;
}

const WelcomePage: React.FunctionComponent<IPage> = (props) => {

    const navigate = useNavigate();

    const { width, height } = useWindowSize()




    return (
        <React.Fragment>

         

         <div className="bg-black text-white welcome-screen">
                <div className="flex h-[100vh] font-['SF-Pro'] px-[80px] font-['SF Pro Display'] mx-auto bg-cover flex-col items-center justify-center ">
                    <div className="bg-[#141414] text-center py-[100px] rounded-[24px] w-[1000px] z-[999] ">
                        <h1 className="text-[32px] font-[600] mb-4">You Are In!</h1>
                        <h2 className="text-[24px] font-[600] mb-8">
                            Welcome to <span className="text-[#05BEF8]">SweatSonic!</span>
                        </h2>
                        <p className="mb-8">
                            You’re subscribed to <b>SweatSonic</b> with a 14-day Free Trial.
                            <br />
                            You can change this anytime from settings.
                        </p>
                        <button className="bg-[#00A3FF] font-[500] w-[210px] text-white font-[500] py-[16px] px-8 rounded-[8px]">
                            Start Exploring
                        </button>
                    </div>
                </div>
            </div>


         
          <Confetti colors={['#00a3ff','#ffc122','#d8d8d8']} />

        </React.Fragment>
    );
};

export default WelcomePage;

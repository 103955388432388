/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/logo.svg";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "assets/css/global.scss";
import { BannerLogo, HumanBody, Logo, Subscribed, Trophy } from "icons";


interface RootState {
    auth: any;
}

const SetGoalsPage: React.FunctionComponent<IPage> = (props) => {

    const navigate = useNavigate();

    const [goals, setGoals] = useState([]);

    useEffect(() => {

        localStorage.setItem("user_goals", goals.join());


    }, [goals]);

    const userGoals = async (name: string) => {

        setGoals([
            ...goals,
            name
        ].filter((item, index, self) => self.indexOf(item) === index))
    }

    return (
        <React.Fragment>

            <div className="text-center h-[68px] bg-[#232323] pt-[10px] pr[0px] pb-[10px] pl-[0px]">
                <BannerLogo className="mx-auto mt-[10px]" />
            </div>

            <div className="bg-[#000] font-['SF-Pro'] text-white flex ite justify-center py-[50px]">
                <div className="w-[500px] rounded-[8px] p-[32px] bg-[#1E1E1E] text-center">
                    <Trophy className="w-[65px] mx-auto" />
                    <h2 className="text-[#D0D5DD] font-[600] text-[16px] mt-[30px] mb-[2px]">
                        Let's get you setup!
                    </h2>
                    <p className="text-[40px] font-[500] mb-4">Set Your Goals</p>
                    <div className="border border-x-0 border-b-0 border-t border-[#757575] mt-[32px] pb-[32px]"></div>
                    <div className="grid grid-cols-2 gap-[5px] mb-8">
                        <button
                            onClick={() => userGoals('Lose Weight')}
                            className={goals.indexOf('Lose Weight') > -1 ? 'w-[177px] h-[98px] ml-auto bg-white text-[#585858] text-[16px] rounded-[8px] py-4 px-4 font-[600]' : 'w-[177px] h-[98px] ml-auto bg-[transparent] text-[#fff] text-[16px] rounded-[8px] py-4 px-4 font-[600] border border-[#A0ABBB]'}>
                            Lose Weight
                        </button>
                        <button
                            onClick={() => userGoals('Build Muscle')}
                            className={goals.indexOf('Build Muscle') > - 1 ? 'w-[177px] h-[98px] bg-[#fff] text-[#585858] mr-auto border border-[#A0ABBB] text-[16px] rounded-[8px] py-4 px-4 font-[600]' : 'w-[177px] h-[98px] hover:bg-[#fff] hover:text-[#585858] mr-auto bg-[transparent] border border-[#A0ABBB] text-[#fff] text-[16px] rounded-[8px] py-4 px-4 font-[600]'}>
                            Build Muscle
                        </button>
                        <button

                            onClick={() => userGoals('Improve Stamina')}

                            className={goals.indexOf('Improve Stamina') > -1 ? 'w-[177px] h-[98px] bg-[#fff] text-[#585858] ml-auto  border border-[#A0ABBB]  text-[16px] rounded-[8px] py-4 px-4 font-[600]' : 'w-[177px] h-[98px] hover:bg-[#fff] hover:text-[#585858] ml-auto bg-[transparent] border border-[#A0ABBB] text-[#fff] text-[16px] rounded-[8px] py-4 px-4 font-[600]'}>
                            Improve Stamina
                        </button>
                        <button

                            onClick={() => userGoals('Maintain')}


                            className={goals.indexOf('Maintain') > -1 ? 'w-[177px] h-[98px] bg-[#fff] text-[#585858] mr-auto  border border-[#A0ABBB]  text-[16px] rounded-[8px] py-4 px-4 font-[600]' : 'w-[177px] h-[98px] hover:bg-[#fff] hover:text-[#585858] mr-auto bg-[transparent] border border-[#A0ABBB] text-[#fff] text-[16px] rounded-[8px] py-4 px-4 font-[600]'}>
                            Maintain
                        </button>
                    </div>
                    <div className="text-[#CACACA] text-left mb-8">
                        <span className="cursor-pointer hover:underline">Skip</span>
                        <p>We use this information to build better routines for you.</p>
                    </div>
                    <div className="flex justify-between space-x-4">
                        <button onClick={() => navigate("/set-profile")} className="bg-[transparent] border border-[#757575] rounded-[6px] w-[205px] h-[46px] text-[#757575] py-2 px-6 rounded-md transition duration-300">
                            Back
                        </button>
                        <button onClick={() => navigate("/subscribe-program")} className="w-[205px] h-[46px] bg-[#00A3FF]  rounded-[6px] hover:bg-[#003D51] text-white py-2 px-6 rounded-md transition duration-300">
                            Next
                        </button>
                    </div>
                </div>
            </div>


        </React.Fragment>
    );
};

export default SetGoalsPage;

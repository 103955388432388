import ForgotPasswordPage from "pages/forgot-password";
import IRoute from "../interfaces/route";
import LoginPage from "../pages/login";
import SignupPage from "pages/signup";
import ResetPasswordPage from "pages/reset-password";
import SubscribeProgramPage from "pages/signup/subscribe-program";
import SetGoalsPage from "pages/signup/set-goals";
import ChoosePlanPage from "pages/signup/choose-plan";
import SetProfilePage from "pages/signup/set-profile";
import VerifyEmailPage from "pages/signup/verify-email";
import WelcomePage from "pages/signup/welcome";
import CheckoutPage from "pages/signup/checkout";
import HomePage from "pages/home";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage,
  },

  {
    path: "/signup",
    name: "Signup Page",
    component: SignupPage,
  },

  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPasswordPage,
  },

  {
    path: "/subscribe-program",
    name: "Subscribe Program",
    component: SubscribeProgramPage,
  },

  {
    path: "/set-goals",
    name: "Set Goals",
    component: SetGoalsPage,
  },

  {
    path: "/choose-plan",
    name: "Choose Plan",
    component: ChoosePlanPage,
  },

  {
    path: "/set-profile",
    name: "Set Profile",
    component: SetProfilePage,
  },

  {
    path: "/verify-email",
    name: "Verify Email",
    component: VerifyEmailPage,
  },

  {
    path: "/welcome",
    name: "Welcome",
    component: WelcomePage,
  },

  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutPage,
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
  },
  
];

export default routes;

/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { BannerLogo } from "icons";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { confirmSignup, verifyCoupon } from "redux/actionCreators/auth";
import { ToastContainer, toast } from 'react-toastify';
import config from "config/config";
import { useNavigate } from "react-router-dom";


const stripePromise = loadStripe(config.stripe_publish_key);

const CheckoutForm = () => {
    const [cardNumberError, setCardNumberError] = useState(false);
    const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState(null);
    const [cardExpiryError, setCardExpiryError] = useState(false);
    const [cardCvcError, setCardCvcError] = useState(false);
    const [coupon, setCoupon] = useState(null);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [plan, setPlan] = useState(0);
    const [planprice, setPlanPrice] = useState(0);
    const [applyingCoupon, setApplyingCoupon] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fullname, setFullName] = useState(null);
    const navigate = useNavigate();
    const elements = useElements();
    const stripe = useStripe();

    const [token, setToken] = useState(null);

    const getToken = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        const res = await stripe.createToken(cardNumberElement);

        console.log(res);

        if (
            res?.error?.code === "incomplete_number" ||
            res?.error?.code === "invalid_number"
        ) {
            setCardNumberError(true);
            setCardNumberErrorMessage(res?.error?.message);

            return null;
        } else {
            setCardNumberError(false);
            setCardNumberErrorMessage(null);
        }

        if (res?.error?.code === "invalid_expiry_year_past" || res?.error?.code === "incomplete_expiry") {
            setCardExpiryError(true);
            return
        } else {
            setCardExpiryError(false);
        }

        if (res?.error?.code === "incomplete_cvc") {
            setCardCvcError(true);
            return
        } else {
            setCardCvcError(false);
        }


        console.log(res?.token?.id);

        setToken(res?.token?.id);

        return res?.token?.id;
    };

    const handleSubmit = async (event) => {


        event.preventDefault();

        setSubmitting(true);

        const token = await getToken();

        console.log("token", token);

        const email = localStorage.getItem("email");

        if (token) {

            if (!fullname) {
                toast.error("Fullname must not empty.")
            }

            const payload = {
                name: fullname || '-',
                token,
                coupon,
                email,
                plan: plan === 1 ? 'yearly' : 'monthly',
            }

            console.log("Payload", payload)

            const res = await confirmSignup(payload);

            if (res.data.error) {

                toast.error(res.data.message);

            } else {

                navigate("/welcome")
            }


        }

        setSubmitting(false);
    };

    useEffect(() => {
        const sub = parseInt(localStorage.getItem("user_plan"));
        setPlan(sub);
        setPlanPrice(config.plans[sub - 1]?.price);
        setTotal(planprice);
    }, [planprice, plan]);

    const handleChange = async (e: any) => {
        await getToken();
    };

    const applyCoupon = async (e) => {

        e.preventDefault();

        if (!coupon) {
            return
        }

        try {

            setApplyingCoupon(true);

            const res = await verifyCoupon({ coupon });

            const discountCode = res.data.results;

            if (discountCode?.valid === false) {

                toast.error("Coupon code expired!");

                return;
            }

            setDiscountPercent(discountCode?.percent_off);

            setDiscount((planprice * discountPercent) / 100);

            console.log("total", total - discount);


            setTotal(total - discount);

            console.log("Discount", discount);

            setApplyingCoupon(false);



        } catch (error) {

            toast.error("Invalid coupon code!");
            setCoupon(null);
            setApplyingCoupon(false);
        }

    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>

                <div className=" text-center h-[68px] bg-[#232323] pt-[10px] pr[0px] pb-[10px] pl-[0px]">
                    <BannerLogo className="mx-auto mt-[10px]" />
                </div>

                <div className="bg-[#000] h-[100vh] max-h-75">
                    <div>
                        <div className="flex w-[926px] font mx-auto py-[50px] justify-center max-[767px]:block">
                            <div className="w-[572px] h-[762px] p-12  rounded-tl-lg rounded-bl-lg  flex-col justify-start items-start gap-9 inline-flex bg-[#1E1E1E]">
                                <div className="flex-col justify-start items-start gap-2 flex">
                                    <div className="self-stretch h-12 font-[600] text-[#FFFFFF] font-['Poppins'] leading-[28px]">
                                        Payment
                                    </div>
                                    <div className="w-[476px] h-[0px] border border-neutral-600"></div>
                                </div>
                                <div className="self-stretch h-[100px] flex-col justify-start items-start gap-4 flex">
                                    <div className="h-[15px] text-white font-[510] font-['SF Pro'] text-[16px] leading-[22px]">
                                        Subscription
                                    </div>
                                    <div className="self-stretch text-white text-sm font-normal font-['SF Pro']">
                                        We offer a 7-day free trial for all of our plans. You can
                                        cancel payment at anytime.
                                    </div>
                                    <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                        <div className="justify-start items-start gap-[19px] flex">
                                            <div className="justify-center items-center gap-2 flex">
                                                <input
                                                    type="radio"
                                                    name="plan"
                                                    onChange={() => {
                                                        localStorage.setItem("user_plan", "1");
                                                        setPlan(1)
                                                    }}
                                                    checked={plan === 1 ? true : false}
                                                    className="w-[15px] left-0 top-0 relative h-[15px] border-2 border-sky-500 rounded-full relatve  bg-[transparent]  checked:bg-[#05BEF8] "
                                                />
                                                <label className="text-white text-base font-['SF Pro'] leading-[19px]">
                                                    Yearly
                                                </label>
                                            </div>
                                        </div>
                                        <div className="justify-start items-start gap-[19px] flex">
                                            <div className="justify-center items-center gap-2 flex">
                                                <input
                                                    type="radio"
                                                    name="plan"
                                                    onChange={() => {
                                                        localStorage.setItem("user_plan", "3");
                                                        setPlan(3)
                                                    }}
                                                    checked={plan === 3 ? true : false}
                                                    className="w-[15px] left-0 top-0 relative h-[15px] border-2 border-sky-500 rounded-full relatve  bg-[transparent]  checked:bg-[#05BEF8] "
                                                />
                                                <label className="text-white text-base font-['SF Pro'] leading-[19px]">
                                                    Monthly
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-[16px] self-stretch h-[305px] flex-col justify-center items-start gap-4 flex">
                                        <div className="self-stretch h-[67px] flex-col justify-center items-start gap-1 flex">
                                            <div className="w-[476px] h-[19px] pr-[401px] justify-start items-center inline-flex">
                                                <div className="text-white text-base font-normal font-['SF Pro'] leading-snug">
                                                    Full Name
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Full Name"
                                                onChange={(e) => setFullName(e.target.value)}
                                                className="self-stretch h-[44px] px-3 rounded-lg  justify-start items-center inline-flex bg-[transparent] border-[#D0D5DD] border text-[#D0D5DD] w-[476px]  py-2 rounded-[8px] mb-3"
                                            />
                                        </div>

                                        <div className="self-stretch h-[90px] flex-col justify-center items-start gap-1 flex">
                                            <div className="w-[476px] h-[19px] pr-[324px] justify-start items-center inline-flex">
                                                <div className="text-white text-base font-normal font-['SF Pro'] leading-snug">
                                                    Credit or Debit Card
                                                </div>
                                            </div>
                                            <CardNumberElement
                                                onChange={handleChange}
                                                options={{
                                                    style: {
                                                        base: {
                                                            color: "#D0D5DD",
                                                            fontSize: "18px",
                                                            fontFamily: "SF Pro",
                                                            fontWeight: "normal",
                                                        },
                                                    },
                                                    showIcon: true,
                                                    placeholder: "3453 3434 7891 1100",
                                                }}
                                                className={
                                                    cardNumberError
                                                        ? "bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0  self-stretch h-[44px] px-3 rounded-lg border-[#F75E7D] border text-[#D0D5DD] w-[476px]  py-2 rounded-[8px] mb-3"
                                                        : "self-stretch h-[44px] px-3 rounded-lg border-[#D0D5DD] border text-[#D0D5DD] w-[476px]  py-2 rounded-[8px] mb-3"
                                                }
                                            />
                                            {cardNumberError && cardNumberErrorMessage && (
                                                <div className="w-[476px] h-[19px] justify-start items-center inline-flex">
                                                    <div className="text-rose-600 text-sm font-normal font-['SF Pro'] leading-[19px]">
                                                        {cardNumberErrorMessage}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="self-stretch justify-start items-start gap-[18px] inline-flex">
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                                                <div className="self-stretch text-white text-base font-normal font-['SF Pro'] leading-snug">
                                                    Expiration Date
                                                </div>
                                                <CardExpiryElement
                                                    onChange={handleChange}
                                                    options={{
                                                        style: {
                                                            base: {
                                                                color: "#D0D5DD",
                                                                fontSize: "18px",
                                                                fontFamily: "SF Pro",
                                                                fontWeight: "normal",
                                                            },
                                                        },
                                                        placeholder: "MM/YY",
                                                    }}
                                                    className={cardExpiryError ? "border-[#F75E7D] bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0 w-[229px]  font-normal font-['SF Pro'] leading-[19px] self-stretch pt-[12px] pr[0px] pb-[12px] pl-[16px] rounded-[4px] border justify-start items-start gap-2.5" : "w-[229px]  font-normal font-['SF Pro'] leading-[19px] self-stretch pt-[12px] pr[0px] pb-[12px] pl-[16px] rounded-[4px] border border-white justify-start items-start gap-2.5"}
                                                />
                                            </div>
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                                                <div className="self-stretch text-white text-base font-normal font-['SF Pro'] leading-snug">
                                                    CVV
                                                </div>
                                                <CardCvcElement
                                                    onChange={handleChange}
                                                    options={{
                                                        style: {
                                                            base: {
                                                                color: "#D0D5DD",
                                                                fontSize: "18px",
                                                                fontFamily: "SF Pro",
                                                                fontWeight: "normal",
                                                            },
                                                        },
                                                        placeholder: "123",
                                                    }}
                                                    className={cardCvcError ? "border-[#F75E7D] bg-[#F4285233] focus:border-current focus:border-red-500 focus:ring-offset-0 focus:outline-none focus:ring-0  w-[229px] font-normal font-['SF Pro'] leading-[19px] self-stretch pt-[12px] pr[0px] pb-[12px] pl-[16px] rounded-[4px] border justify-start items-start gap-2.5" : "w-[229px] font-normal font-['SF Pro'] leading-[19px] self-stretch pt-[12px] pr[0px] pb-[12px] pl-[16px] rounded-[4px] border border-white justify-start items-start gap-2.5"}
                                                />
                                            </div>
                                        </div>
                                        <div className="self-stretch justify-start items-center gap-4 inline-flex">
                                            <input
                                                type="checkbox"
                                                className="bg-[transparent] w-4 h-4 relative rounded-sm border border-white"
                                            />
                                            <label className="text-white text-base font-normal font-['SF Pro'] leading-[19px]">
                                                Save card details
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex-col justify-start items-start gap-[23px] flex">
                                        <div className="self-stretch justify-start items-center gap-4 inline-flex">
                                            <div>
                                                <span className="text-white text-sm font-normal font-['SF Pro'] leading-[19px]">
                                                    Already a member?{" "}
                                                </span>
                                                <a href="/" className="cursor-pointer text-sky-500 text-sm font-normal font-['SF Pro'] underline leading-[19px]">
                                                    Login
                                                </a>
                                            </div>
                                        </div>
                                        <p className="w-[475px] h-[51px] text-white text-[14px] font-[400] font-['Poppins'] leading-[17px]">
                                            You will only be charged after 7 days. If you’re using a
                                            card not issued in the United States, contact your card
                                            provider and make sure it’s unlocked for purchases in the
                                            US territory.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-[572px] h-[762px] p-12 bg-neutral-900 rounded-tr-lg rounded-br-lg flex-col justify-start items-start gap-9 inline-flex">
                                <div className="flex-col justify-start items-start gap-2 flex">
                                    <div className="self-stretch h-12 text-white text-[28px] font-['SF Pro'] leading-[34px]">
                                        Order Summary
                                    </div>
                                    <div className="w-[476px] h-[0px] border border-neutral-600"></div>
                                </div>
                                <div className="flex-col justify-center items-start flex">
                                    <div className="w-[476px] justify-start items-start gap-4 inline-flex">
                                        <img
                                            alt=""
                                            className="w-[70px] h-[70px] relative rounded border border-white"
                                            src="https://content.sweatsonic.com/frame-icon.png"
                                        />
                                        <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex">
                                            <div className="self-stretch h-[25px] justify-start items-start gap-[125px] inline-flex">
                                                <div className="w-[197px] text-white text-lg font-['SF Pro']">
                                                    {plan === 1 ? "Annual Subscription" : "Monthly Subscription"}
                                                </div>
                                                <div className="w-[68px] h-[25px] text-white text-base font-normal font-['SF Pro'] leading-snug">
                                                    ${planprice}
                                                </div>
                                            </div>
                                            <div className="self-stretch pl-px pr-2 justify-start items-start inline-flex">
                                                <div className="grow shrink basis-0 h-[25px] text-white text-base font-normal font-['SF Pro']">
                                                    SweatSonic
                                                </div>
                                                <div className="w-[51px] h-[46px] text-white text-base font-normal font-['SF Pro']">
                                                    Qty: 1
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="self-stretch h-[83px] flex-col justify-start items-start gap-9 flex">
                                    <div className="self-stretch h-[0px] border border-neutral-600"></div>
                                    <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                        <div className="">
                                            <input
                                                type="text"
                                                onChange={(e) => setCoupon(e.target.value.trim())}
                                                placeholder="Gift or discount code"
                                                className="w-[230px] h-[46px] grow shrink basis-0  pl-4  py-3.5 bg-white rounded border border-white justify-start items-start gap-2.5 flex text-black text-base font-normal font-['SF Pro'] "
                                            />
                                        </div>
                                        <div className="">
                                            <button
                                                type="button"
                                                disabled={applyingCoupon}
                                                onClick={applyCoupon}
                                                className="disabled:opacity-75 w-[230px] h-[46px] text-zinc-100 text-base font-normal font-['SF Pro'] grow shrink basis-0 px-[23px] py-[13px] bg-sky-500 rounded-lg  justify-center items-center gap-2.5 flex">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>



                                {discount && (

                                    <>
                                        <div className="flex-col justify-center items-start gap-6 flex">
                                            <div className="w-[476px] h-[0px] border border-neutral-600"></div>
                                            <div className="flex-col justify-start items-start gap-4 flex">
                                                <div className="justify-start items-start gap-[346px] inline-flex">
                                                    <div className="w-[70px] h-[25px] text-white text-sm font-['SF Pro'] leading-[17px]">
                                                        Discount
                                                    </div>
                                                    <div className="w-[60px] h-[25px] text-white text-base font-normal font-['SF Pro'] leading-[19px]">
                                                        ${discount}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="flex-col justify-center items-start gap-6 flex">
                                    <div className="w-[476px] h-[0px] border border-neutral-600"></div>
                                    <div className="flex-col justify-start items-start gap-4 flex">
                                        <div className="justify-start items-start gap-[346px] inline-flex">
                                            <div className="w-[70px] h-[25px] text-white text-sm font-['SF Pro'] leading-[17px]">
                                                Subtotal
                                            </div>
                                            <div className="w-[60px] h-[25px] text-white text-base font-normal font-['SF Pro'] leading-[19px]">
                                                ${total}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-col justify-start items-start gap-6 flex">
                                    <div className="w-[476px] h-[0px] border border-neutral-600"></div>
                                    <div className="w-[473px] justify-start items-center inline-flex">
                                        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                            <div className="self-stretch h-[22px] text-white text-base font-bold font-['SF Pro'] leading-[19px]">
                                                Total
                                            </div>
                                        </div>
                                        <div className="text-white text-[32px] font-['SF Pro'] leading-10">
                                            ${total}
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <button
                                        disabled={submitting}
                                        className="disabled:opacity-75 text-zinc-100 text-[20px] font-['SF Pro'] leading-normal w-[476px] h-[64px] pt-[20px] pr[193px] pb-[20px] bg-sky-500 rounded-lg justify-center items-center gap-[10px] inline-flex">
                                        Checkout
                                    </button>
                                </div>
                                <div className="">
                                    <button type="button" onClick={() => {
                                        navigate("/choose-plan")
                                    }} className="text-zinc-100 text-[20px] font-['SF Pro'] leading-normal w-[476px] h-[64px] px-[193px] py-[20px] rounded-lg border border-zinc-500 justify-center items-center gap-2.5 inline-flex">
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme='dark'
            />

        </React.Fragment>
    );
};

const CheckoutPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default CheckoutPage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import { useNavigate } from "react-router-dom";
import { BannerLogo, Heart } from "icons";

import BackgroundImg from "assets/img/subscription-left-box.png";

interface RootState {
  auth: any;
}

const ChoosePlanPage: React.FunctionComponent<IPage> = (props) => {
  const navigate = useNavigate();

  const [plan, setPlan] = useState(0);

  useEffect(() => {

       const user_plan = localStorage.getItem("user_plan");

       setPlan(parseInt(user_plan));

  },[])

  return (
    <React.Fragment>
      <div className=" text-center h-[68px] bg-[#232323] pt-[10px] pr[0px] pb-[10px] pl-[0px]">
        <BannerLogo className="mx-auto mt-[10px]" />
      </div>

      <div className="bg-[#000] font-['SF Pro Display'] h-[100vh] max-h-75">
        <div>
          <div className="flex w-[926px] font mx-auto py-[50px] justify-between max-[767px]:block">
            <div
              style={{ backgroundImage: "url(" + BackgroundImg + ")" }}
              className={`col-1 font-['Poppins-Med'] max-[767px]:w-[100%] justify-center items-center w-[50%] flex flex-col  bg-cover`}
            >
              <p className="text-[20px] text-[#E7EAEE] font-[500] text-center">
                Almost There!
              </p>
              <h1 className="text-[48px] font-[600] text-[#E7EAEE] text-center">
                Join <span className="text-[#05BEF8]">SweatSonic</span>
              </h1>
              <p className="text-[14px] font-['Pop-L'] text-[#E7EAEE] font-[500] text-center">
                Start with a 14-day free trail, cancel anytime!
              </p>
            </div>
            <div className="w-[50%] font-['SF-Pro'] bg-[#1E1E1E] py-[32px] px-[30px] flex justify-center flex-col max-[767px]:w-[100%] max-[767px]:px-[24px] max-[767px]:py-[100px]">
              <div className="mb-[24px]">
                <Heart className="w-[65px] mx-auto" />
              </div>
              <h1 className="text-[16px] text-center text-[#D0D5DD]">
                Subscription
              </h1>
              <h2 className="text-[40px] text-[#E7EAEE] text-center">
                Choose Your Plan
              </h2>
              <p className="w-[274px] mx-auto text-center text-[#D3D3D3] pt-[5px] text-[14px]">
                When you subscribe, you’ll get instant unlimited access and a
                free 14-day trial!
              </p>
              <div className="border border-x-0 border-b-0 border-t border-[#A0ABBB] mt-[32px] pb-[32px]"></div>
              {/* <span className="cursor-pointer mb-[20px] text-[#A0ABBB] hover:underline">
                Skip
              </span> */}
              <div onClick={() => {
                setPlan(1);
                localStorage.setItem("user_plan","1");

              }} className={`${plan === 1 ? 'border bg-[#00A3FF1A] border-[#05BEF8]' : 'bg-[#6464641A]'} checked:bg-[#00A3FF1A] checked:border-[#05BEF8]  flex  justify-end rounded-[8px] items-center mb-[16px]`}>
                <input
                  id="default-radio-1"
                  type="radio"
                  onClick={() => setPlan(1)}
                  checked={plan === 1 ? true : false}
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ml-[16px] dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-[4px] flex-[1_0_50%]  ms-2 py-[12px] px-[6px] text-[16px] font-[590] font-medium text-[#F7F8F9] dark:text-gray-300"
                >
                  Annual
                  <br />
                  <span className="flex items-center text-[#E7EAEE] text-[14px] leading-[14px] font-[400]">
                    12 mo &nbsp;
                    <svg
                      width="3"
                      height="4"
                      viewBox="0 0 3 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
                    </svg>
                    &nbsp;$240
                  </span>
                </label>
                <span className="mt-0 mr-[42px]  mb-0 ml-[3px] text-[#F7F8F9] font-[400] text-[14px] leading-[14px]">
                  $240/y
                </span>
              </div>
              {/* <div onClick={() => setPlan(2)} className={`${plan === 2 ? 'border bg-[#00A3FF1A] border-[#05BEF8]' : 'bg-[#6464641A]'}  checked:bg-[#00A3FF1A] checked:border-[#05BEF8]  flex  justify-end  rounded-[8px] items-center mb-[16px]`}>
                <input
                  id="default-radio-2"
                  type="radio"
                  onClick={() => setPlan(2)}
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ml-[16px] dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-2"
                  className="ml-[4px] flex-[1_0_50%]  ms-2 py-[12px] px-[6px] text-[16px] font-[590] font-medium text-[#F7F8F9] dark:text-gray-300"
                >
                  6-Month
                  <br />
                  <span className="flex items-center text-[#E7EAEE] text-[14px] leading-[14px] font-[400]">
                    6 mo &nbsp;
                    <svg
                      width="3"
                      height="4"
                      viewBox="0 0 3 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
                    </svg>
                    &nbsp;$210
                  </span>
                </label>
                <span className="mt-0 mr-[55px] mb-0 ml-[3px] text-[#F7F8F9] font-[400] text-[14px] leading-[14px]">
                  $210
                </span>
              </div> */}
              <div onClick={() => setPlan(3)} className={`${plan === 3 ? 'border bg-[#00A3FF1A] border-[#05BEF8]' : 'bg-[#6464641A]'} checked:bg-[#00A3FF1A] checked:border-[#05BEF8]  flex  justify-end rounded-[8px] items-center mb-[16px]`}>
                <input
                  id="default-radio-3"
                  type="radio"
                  checked={plan === 3 ? true : false}
                  onClick={() => {
                    setPlan(3)
                    localStorage.setItem("user_plan","3");
                  }}
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ml-[16px] dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-3"
                  className="ml-[4px] flex-[1_0_50%]  ms-2 py-[12px] px-[6px] text-[16px] font-[590] font-medium text-[#F7F8F9] dark:text-gray-300"
                >
                  Monthly
                  <br />
                  <span className="flex items-center text-[#E7EAEE] text-[14px]">
                    1 mo &nbsp;
                    <svg
                      width="3"
                      height="4"
                      viewBox="0 0 3 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="1.5" cy="2" r="1.5" fill="#D9D9D9" />
                    </svg>
                    &nbsp;$39.99
                  </span>
                </label>
                <span className="mt-0 mr-[45px]  mb-0 ml-[3px] text-[#F7F8F9] font-[400] text-[14px] leading-[14px] ">
                  $39/m
                </span>
              </div>
              <button
                onClick={() => navigate("/checkout")}
                className="w-full hover:bg-[#003D51]  font-[500] h-[46px] bg-[#00A3FF] rounded-[6px] hover:bg-[#003D51] text-white py-2 px-6 underline rounded-md"
              >
                Start Free Trial
              </button>
              <p className="mt-[16px] font-['Pop-L'] text-[#8F8F8F] text-[12px] mb-[16px]">
                and then $39.99 per month
              </p>

              <p className="text-[12px] font-[400] font-['SF Pro'] leading-[14px] text-justify tracking-[0em] text-[#8F8F8F]">
                FOR A LIMITED TIME, IF YOU PURCHASE 1 ANNUAL PASS, YOU WILL BE
                ABLE TO GIFT 1 EXTRA  ANNUAL PASS. THEY WILL RECEIVE A
                NOTIFICATION THAT YOU PURCHASED THEM AN ANNUAL PASS IN ORDER TO
                COMMIT TOGETHER. PAY IT FORWARD.
              </p>
              <p className="text-[12px] mt-[10px] font-[400] font-['SF Pro'] leading-[14px] text-justify tracking-[0em] text-[#8F8F8F]">
                You will only be charged after 7-days. If you’re using a card
                not issued in the United States, contact your card provider and
                make sure it’s unlocked for purchases in the US territoriy
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChoosePlanPage;
